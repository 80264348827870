

<template>
  <div class="my-real-name-warp">
    <div class="title">
      实名认证
    </div>
    <div class="line"></div>
    <a-form-model labelAlign="left" :colon="false" ref="formData" :model="form_data" :label-col="{ span: 4 }" :wrapper-col="{ span: 18}" layout="horizontal" :rules="ruleValidate" class="edit-form">
      <a-form-model-item label="真实姓名" prop="name">
        <a-input  v-model="form_data.name" placeholder="请与身份证保持一致"/>
      </a-form-model-item>
      <a-form-model-item label="身份证号" prop="certificate_number">
        <a-input v-model="form_data.certificate_number" type="letter-numbers" :maxlength="18" placeholder="18位身份证号码" />
      </a-form-model-item>
      <a-form-model-item label="身份证照片" prop="certificate_img_front">
        <div style="display:flex">
          <img-upload v-model="form_data.certificate_img_front" text="身份证正面" style="margin-right:12px" @imgChange="imgChange"></img-upload>
          <img-upload v-model="form_data.certificate_img_backend" text="身份证反面" @imgChange="imgChange"></img-upload>
        </div>
        <p class="sub-text">上传个人身份证正反面照片，请确保身份证在有效期内，并且保持照片清晰。支持 jpg、png 格式，大小 5MB 以内</p>
      </a-form-model-item>
      <a-form-model-item label=" " prop="sure_read" style="margin-top: -20px">
        <a-checkbox v-model="form_data.sure_read">
           已阅读并同意 <a class="protocol" target="_blank" :href="user_protocol">《用户服务协议》</a> <a class="protocol" target="_blank" :href="real_protocol">《实名认证规范》</a>
        </a-checkbox>
      </a-form-model-item>
      <a-form-model-item label=" " style="margin-top: -10px">
        <a-button class="submit-btn" :disabled="!can_submit" :class="{active: can_submit}" type="primary" @click="submit">提交申请</a-button>
        <a-button class="cancel-btn" @click="goBack">取消</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
let id_reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/

import { mapState } from 'vuex'
import ImgUpload from './components/imgUpload.vue'
export default {
  name: 'realNameInfo',
  created () {
    this.initInfo()
  },
  components: {ImgUpload},
  data () {
    const validateGenerator = (key, errMsg) => {      
      const validator = (rule, value, callback) => {
        if (key == 'sure_read') {
          this.read_check = false
        }
        if (key == 'name') {
          this.name_check = false
        }
        if (key == 'certificate_number') {
          this.number_check = false
        }
        if (Object.prototype.toString.call(this.form_data[key]) == '[object Array]' && this.form_data[key].length == 0) {
          callback(new Error(errMsg || ' '))
        } else if (!this.form_data[key]) {
          callback(new Error(errMsg || ' '))
        } else {
          if (key == 'sure_read') {
            this.read_check = true
          }
          if (key == 'name') {
            this.name_check = true
          }
          if (key == 'certificate_number') {
            this.number_check = true
          }
          callback()
        }
      }
      return validator
    }
    const validateUrl = (rule, value, callback) => {
      this.img_check = false
      if (!this.form_data.certificate_img_front && !this.form_data.certificate_img_backend) {
        callback('请上传身份证图片')
      } else if(!this.form_data.certificate_img_front) {
        callback('请上传身份证正面图片')
      } else if(!this.form_data.certificate_img_backend) {
        callback('请上传身份证背面图片')
      } else {
        this.img_check = true
        callback()
      }
    }
    const validateId = (rule, value, callback) => {
      this.number_check = false
      if (!this.form_data.certificate_number) {
        callback('请输入身份证号')
      } else if(!id_reg.test(this.form_data.certificate_number)) {
        callback('请输入正确的18位身份证号')
      } else {
        this.number_check = true
        callback()
      }
    }
    return {
      ruleValidate: {
        sure_read:[ { validator: validateGenerator('sure_read', '请先阅读《用户服务协议》《实名认证规范》'), trigger: 'change' } ],
        name: [ { required: true, validator: validateGenerator('name', '请填写姓名'), trigger: 'blur' } ],
        certificate_number: [ { required: true, validator: validateId, trigger: 'blur' } ],
        certificate_img_front: { required: true, validator: validateUrl, trigger: 'blur'},
      },
      form_data: {},
      name_check: false,
      number_check: false,
      img_check: false,
      read_check: false,
    }
  },
  computed: {
    can_submit() {
      return this.name_check && this.number_check && this.img_check && this.read_check
    }
  },
  methods: {
    imgChange() {
      this.$nextTick(() => {
        this.$refs.formData.validateField('certificate_img_front')
      })
    },
    async initInfo() {
      let res = await AuthService.realNameInfo({plaintext: true})
      if (res) {
        this.form_data = res || {}
        if (this.form_data.id) {
          this.$nextTick(() => {
            this.$refs.formData.validateField('name')
            this.$refs.formData.validateField('certificate_number')
            this.$refs.formData.validateField('certificate_img_front')
          })
        }
      }
    },
    goBack() {
      this.$router.back()
      // this.$router.push('/bpy_myInfo')
    },
    submit() {
      this.$refs.formData.validate(async valid => {
        if (valid) {
          let res = await AuthService.realNameCreate(this.form_data)
          if (res) {
            this.$message.success('提交审核成功')
            this.goBack()
          }
          // if (this.form_data.id) {
          // } else {
          //   let res = await AuthService.realNameCreate(this.form_data)
          //   if (res) {
          //     this.$message.success('提交审核成功')
          //     this.goBack()
          //   }
          // }
        }
      })
    }
  }
}
</script>
<template>
  <div>
    <div class="no_img_url" v-if="!value" @click="selectFile()">
      <div class="up-icon">
        <img src="/images/icon-imageadd.png" alt="" srcset="" />
      </div>
      <span>{{text || '上传图片'}}</span>
    </div>
    <div class="has_img_url" v-if="value">
      <img :src="value" alt="">
      <div class="edit-mask">
        <p class="lookurl" @click="preView(value)">查看图片</p>
        <p class="lookline"></p>
        <p class="reUpdate" @click="selectFile()">重新上传</p>
      </div> 
    </div>
  </div>
</template>
<style scoped>

</style>
<script>
  export default {
    name: 'ImgUpload',
    created() {

    },
    props: {
      value: {
        type: [String],
        default: ''
      },
      text: {
        type: [String],
        default: ''
      }
    },
    data () {
      return {
        current_value: '',
      }
    },
    watch: {
      current_value: {
        handler(val) {
          if (val === this.val) {
            return
          }
          this.$emit('input', val)
        }
      },
      value: {
        handler(val) {
          if (val === this.current_value) {
            return
          }
          this.current_value = val;
        },
        immediate: true
      }
    },
    mounted () {

    },
    beforeDestroy () {

    },
    computed: {
      
    },
    methods: {
      selectFile() {
        Upload2Oss({check_type: 2, max_size: 5}, (res) => {
          if (res && res[0]) {
            this.current_value = res[0].url
            this.$emit('imgChange')
          }
        })
      },
      preView() {
        this.$ImgPreview({urls: [this.current_value]})
      }
    }
}
</script>